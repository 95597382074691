import 'app/webpackNonce'
import {initializeTranslator} from 'app/initializeTranslator'

Promise.all([
  devMode ? import(/* webpackChunkName: "developerAppStyles" */ 'app/initializeDeveloperAppStyles') : Promise.resolve(),
  initializeTranslator(),
  import('app/initializeDeveloperApp').then(x => x.initializeDeveloperApp),
]).then(([_, translator, initializeDeveloperApp]) => {
  initializeDeveloperApp(translator)
})
